//window._ = require("lodash");
// window.Popper = require("popper.js").default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = window.jQuery = require("jquery/dist/jquery");
require("ion-rangeslider");

require("intl-tel-input/build/js/intlTelInput-jquery.js");
require("intl-tel-input/build/js/utils.js");

require("chosen-js");

require("bootstrap/js/dist/modal");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/tab");
require("bootstrap/js/dist/carousel");

require("pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min");

require("select2/dist/js/select2");
require("select2/dist/js/i18n/fr.js");

require("slick-carousel/slick/slick");

require("dropzone/downloads/dropzone.js");

require("./validationEngine/js/languages/jquery.validationEngine-fr");
require("./validationEngine/js/jquery.validationEngine");

require("jquery.fancybox/source/jquery.fancybox.js");

require("inputmask/dist/jquery.inputmask.bundle.js");

window.L = require("leaflet/dist/leaflet");

$(function() {
  $(function () {
    $('.carousel').carousel();
  });
});

/*
  ;

  $.fn.select2.defaults.set("theme", "bootstrap4");

  */

// Disable autocomplete in chrome for select2:
// @see https://github.com/select2/select2/issues/3313
window.setTimeout(() => {
  $("input.select2-input").attr("autocomplete", "xxxxxxxxxxx");
}, 1000);

$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
  }
});
