/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./packages");

window.initLeaflet = function(id, lat, lng, options) {
  var map = new L.Map(id, {
    center: new L.LatLng(lat, lng),
    zoom: 15
  });

  var icon = options && options.icon || 'bien';
  var draggable = options && options.draggable || false;
  var getMarker = options && options.getMarker || false;

  L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFjaG91ciIsImEiOiJjamR1bGtub3kwMXQ5MndzNGJxNDdodnFhIn0.7UPBZyj0x_NwLKxXEk47-w', {
    //attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox.streets',
    accessToken: 'pk.eyJ1IjoibWFjaG91ciIsImEiOiJjamR1bGtub3kwMXQ5MndzNGJxNDdodnFhIn0.7UPBZyj0x_NwLKxXEk47-w'
  }).addTo(map);

  var marker = L.marker([lat, lng], {
    icon: L.icon({
      iconSize: [42, 48],
      iconUrl: 'https://www.immobilier.com.tn/uploads/points/' + icon + '.png'
    }),
    zIndexOffset: 1000,
    draggable: draggable
  }).addTo(map);

  if (getMarker) {
    return marker;
  }

  return map;

};

$(function() {

  $('.datepicker').datetimepicker({format: 'DD-MM-Y'});

  $('.chosen-select').chosen({disable_search_threshold: 10});

  $(".fancybox").fancybox({
    padding: 0,
    helpers: {
      overlay: {
        locked: false
      }
    }
  });

});


